import styled from 'styled-components';

export const StyledPage = styled.div`
  background: #3d3f46;
  min-height: 100vh;
  width: 100vw;
`;

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: #3d3f46;
  z-index: 100;
  color: #ffffff;
  padding: 0px 20px;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding: 0px 50px 0px 50px;
    height: 800px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding: 0px 100px 0px 60px;
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 40px 0px;

  svg {
    width: 80px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding: 30px 0px 80px 0px;

    svg {
      width: 120px;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding-left: 150px;

    svg {
      width: 145px;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    padding: 50px 800px 100px 150px;

    svg {
      width: 145px;
    }
  }
`;

export const StyledContent = styled.div`
  width: 1450px;
  position: relative;
  max-width: 100%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding: 0px 10%;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding: 0px 15%;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    padding-bottom: 0px;
  }
`;

export const StyledCross = styled.div`
  position: absolute;
  top: 3vh;
  right: 5vw;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;

  svg {
    width: 50%;
    fill: ${props => props.theme.primaryCherry};
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    top: 3vh;
    width: 48px;
    height: 48px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
  }
`;

export const StyledTitle = styled.h1`
  font-size: 32px;
  padding-bottom: 34px;
  font-weight: normal;

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    font-size: 48px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    font-size: 48px;
    padding-bottom: 86px;
  }
`;

export const StyledRowTitle = styled.h2`
  font-size: 26px;
  margin-bottom: 16px;
  font-weight: normal;

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    font-size: 36px;
  }
`;

export const StyledCookieRow = styled.div`
  padding: 0px 0px 40px 0px;
  width: 100%;
  max-width: 100%;
  position: relative;
`;

export const StyledCookieHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    flex-direction: row;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    display: flex;
    align-items: center;
  }
`;

export const StyledCookieText = styled.div`
  font-size: 14px;
  margin-top: 30px;
  line-height: 1.5em;

  ul {
    padding-left: 20px;

    li {
      padding: 2px 0px 2px 10px;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    font-size: 16px;
    margin-top: 17px;
    line-height: 24px;
    width: 757px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    ul {
      padding-left: 20px;
    }
  }
`;

export const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledFooter = styled.div`
  padding-top: 10px;
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  flex-direction: row;
  font-size: 16px;
  font-weight: 400;
`;

export const StyledFooterContainer = styled.div`
  width: 100%;
  background: #3d3f46;
  z-index: 100;
  color: #ffffff;
  padding: 0px 20px;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding: 0px 50px 0px 50px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding: 0px 100px 0px 60px;
  }

  @media (min-height: 900px) {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
  }
`;

export const StyledFooterContent = styled.div`
  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding: 0px 10% 0px 10%;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding: 0px 15% 0px 15%;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    padding-bottom: 0px;
  }
`;

export const StyledFooterText = styled.p`
  opacity: 0.5;
  color: #fff;
`;

export const StyledLogos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledLogo = styled.a`
  cursor: pointer;
  width: 80px;
  text-align: right;
`;
