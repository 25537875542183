export const setCookie = (name, value, expiresIn, domain) => {
  let expires = '';
  if (expiresIn) {
    expires = getDateFromNow(expiresIn);
  }
  document.cookie = `${name}=${value};expires=${expires};domain=${domain};path='/'`;
};

export const searchCookie = part => {
  const cookieMatches = document.cookie
    .split('; ')
    .map(elem => {
      const cookie = elem.split('=');
      const key = cookie[0];

      return key.includes(part) ? key : null;
    })
    .filter(Boolean);

  return cookieMatches;
};

export const getDateFromNow = ({ years = 0, months = 0, days = 0 } = {}) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + years);
  date.setMonth(date.getMonth() + months);
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

  return date.toUTCString();
};
