import React from 'react';
import Carousel from '../Carousel';
import { graphql, useStaticQuery } from 'gatsby';
import { useRef } from 'react';
import { useWindowWidth } from '../../context/WindowWidthContext';
import { StyledCarouselSlideWrapper } from '../Carousel/styles';
import styled from 'styled-components';

export default function Introduction() {
  const { contentfulCarousel } = useStaticQuery(graphql`
    query {
      contentfulCarousel(entryTitle: { eq: "Top Carousel" }) {
        contentful_id
        heading
        copy {
          copy
        }
        carouselSlides {
          image {
            id
            fluid {
              src
            }
          }
        }
      }
    }
  `);

  const wrapper = useRef();
  // const [mapLink, setMapLink] = React.useState(false);

  function mailToSam() {
    return (window.location.href = 'mailto:samantha.walsh@cherrythinking.com');
  }

  const { width } = useWindowWidth();

  React.useEffect(() => {
    const allPs = Array.from(wrapper.current.getElementsByTagName('p'));
    const element = allPs.filter(p =>
      p.innerHTML.includes('Get in touch to find out more'),
    )[0];

    if (element) {
      element.style.cursor = 'pointer';
      element.style.marginTop = '15px';

      element.addEventListener('click', mailToSam);
    }

    return () => {
      if (element) {
        element.removeEventListener('click', mailToSam);
      }
    };
  }, [width]);

  const introCopy = `We're a full-service healthcare creative agency.

  So, what makes us different from other full-service healthcare creative agencies?
  
  We nurture our clients and grow with them. Helping their growth by growing their brands. In turn, they help grow ours. This synergy is why we continue to work in harmony with our clients.
  
  __Get in touch to find out more__
  `;

  return (
    <Wrapper ref={node => (wrapper.current = node)}>
      <Carousel
        data={{ ...contentfulCarousel, copy: { copy: introCopy } }}
        viewName="intro"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #ffffff;
  ${StyledCarouselSlideWrapper} {
    .slick-slide {
      align-self: center;
    }
  }
`;
