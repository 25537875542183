import styled from 'styled-components';

export const StyledVideoContainer = styled.div`
  height: 100vh;
  min-height: 568px;
  background: #000;

  display: flex;
  align-items: center;
  /* -ms-flex-align: center; */
  justify-content: center;
  position: relative;
`;

export const StyledPlayButton = styled.div`
  cursor: pointer;
  width: 120px;
  height: 120px;

  background: black;

  border-radius: 50%;

  /* z-index: 10; */

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50%;
    height: 50%;

    padding-left: 12px;
  }

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    top: -50%;
  } */
`;

export const StyledOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* z-index: 9999; */
`;

export const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(${props => props.bgurl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
