import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledTitle,
  StyledCookieRow,
  StyledRowTitle,
  StyledCookieHeader,
  StyledCookieText,
  StyledLink,
  StyledContent,
} from './styles';
import SwitchButton from '../SwitchButton';
import Modal from '../Modal';
import { setCookie } from '../../helpers/cookieUtils';
import { useScroll } from '../Scroller';

export default function CookieSettings({
  closeModal,
  setModal,
  setCookies,
  cookieConsent,
  setLocation,
  openPrivacyPolicy,
}) {
  const { scrollTo } = useScroll();

  return (
    <Modal
      closeModal={closeModal}
      scrollTo={scrollTo}
      setModal={setModal}
      setLocation={setLocation}
      openCookieSettings={() => {
        setModal('cookieSettings');
      }}
      openPrivacyPolicy={() => {
        setModal('privacyPolicy');
      }}
    >
      <StyledContent>
        <StyledTitle>Cookie settings</StyledTitle>
        <StyledCookieRow>
          <StyledCookieHeader>
            <StyledRowTitle>Performance cookies</StyledRowTitle>
            <SwitchButton onChange={setCookies} isChecked={cookieConsent} />
          </StyledCookieHeader>
          <StyledCookieText>
            Our performance cookies use Google Analytics to collect and store
            anonymised information on:
            <ul>
              <li>how you got to the site</li>
              <li>
                the pages you visit on our site, and how long you spend on each
                page
              </li>
              <li>what you click on while you're visiting the site</li>
            </ul>
            We use Google Analytics to measure how you use the website so we can
            improve it based on your needs. We do not allow Google to use or
            share the data about how you use this site.
          </StyledCookieText>
        </StyledCookieRow>
        <StyledCookieRow style={{ paddingBottom: '100px' }}>
          <StyledCookieHeader>
            <StyledRowTitle>Essential cookies</StyledRowTitle>
            <SwitchButton essential={true} isChecked={true} />
          </StyledCookieHeader>
          <StyledCookieText>
            Our essential cookies remember the notifications you've seen so we
            do not show them again. They always need to be on so that our
            website can work properly. Find out more about our{' '}
            <StyledLink onClick={openPrivacyPolicy}>cookies policy</StyledLink>.
          </StyledCookieText>
        </StyledCookieRow>
      </StyledContent>
    </Modal>
  );
}

CookieSettings.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setCookies: PropTypes.func.isRequired,
  cookieConsent: PropTypes.bool.isRequired,
  setModal: PropTypes.func,
  scrollTo: PropTypes.func,
  setLocation: PropTypes.func,
};

CookieSettings.defaultProps = {
  cookieConsent: false,
};
