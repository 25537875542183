import styled from 'styled-components';

export const StyledLink = styled.a`
  grid-row: 1;
`;

export const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 568px;
  display: flex;
  flex-direction: column;
  /* display: grid;
  grid-template-rows: 48% 1% 1fr;

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    grid-template-rows: 70% 1% 1fr;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    grid-template-rows: 65% 1% 1fr;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    grid-template-rows: 65% 1% 1fr;
  } */
`;

export const StyledMapImage = styled.img`
  width: 100%;
  height: 100%;

  transition: all 1s ease-in-out;

  :hover {
    transform: scale(1.05);
  }
`;
