import React from 'react';
import { Wrapper, Label, Input, SwitchButton, Text } from './styles';

const Switch = ({ onChange, isChecked, essential }) => {
  const handleChange = e => {
    onChange && onChange(e.target.checked);
  };

  return (
    <Wrapper>
      <Label>
        <Input
          style={{ display: 'none' }}
          checked={isChecked}
          onChange={handleChange}
          type="checkbox"
        />
        <SwitchButton isEssential={essential} isChecked={isChecked}>
          {['On', 'Off'].map((text, index) => {
            return (
              <Text
                key={text}
                style={{
                  left: index === 0 ? '10px' : 'auto',
                  right: index === 1 ? '10px' : 'auto',
                  opacity:
                    (index === 0 && isChecked) || (index === 1 && !isChecked)
                      ? 1
                      : 0,
                }}
              >
                {text}
              </Text>
            );
          })}
        </SwitchButton>
      </Label>
    </Wrapper>
  );
};

export default Switch;
