import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  StyledPage,
  StyledContainer,
  StyledHeader,
  InnerWrapper,
  CloseIcon,
} from './styles';
import CherryLogo from '../../images/cherryLogo.svg';

import {
  StyledHamburgerWrapper,
  StyledHamburger,
} from '../HamburgerMenu/styles';
import Footer from '../Footer';

const Modal = ({ closeModal, children, setModal, setLocation, hideClose }) => {
  return (
    <StyledPage>
      <InnerWrapper>
        <StyledContainer>
          <StyledHeader>
            <CherryLogo />
            {hideClose !== 'true' && (
              <HamburgerWrapper
                showHamburger={true}
                onClick={closeModal}
                animateNav={true}
              >
                <HamburgerIcon animateNav={true}></HamburgerIcon>
              </HamburgerWrapper>
            )}
          </StyledHeader>
          {children}
        </StyledContainer>
        <Footer
          displayMenu={true}
          setModal={setModal}
          closeModal={closeModal}
          setLocation={setLocation}
          openCookieSettings={() => setModal('cookieSettings')}
          openPrivacyPolicy={() => setModal('privacyPolicy')}
          openRecruitmentPolicy={() => setModal('recruitmentPolicy')}
        />
      </InnerWrapper>
    </StyledPage>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  setModal: PropTypes.func,
  scrollTo: PropTypes.func,
  setLocation: PropTypes.func,
};

export default Modal;

const HamburgerWrapper = styled(StyledHamburgerWrapper)`
  @media screen and (min-width: 1440px) {
    background-color: #ffffff;
  }
`;
const HamburgerIcon = styled(StyledHamburger)`
  &:before,
  &:after {
    background-color: rgb(145, 0, 75);
  }
`;
