import styled from 'styled-components';

export const StyledGoogleMapOptions = {
  scrollwheel: false,
  gestureHandling: 'cooperative',
  styles: [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2',
        },
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: '-100',
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
        {
          saturation: '-100',
        },
        {
          lightness: '41',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [
        {
          color: '#959c9f',
        },
        {
          visibility: 'on',
        },
      ],
    },
  ],
};

export const StyledPicker = styled.div`
  width: 40px;
  height: 45px;

  z-index: 5;
  position: relative;
  left: -20px;
  top: -45px;
`;

export const StyledDirections = styled.div`
  width: 178px;
  height: 50px;

  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;

  a {
    text-decoration: none;
  }
`;

export const MapWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;

  > div {
    height: auto !important;
  }
`;
