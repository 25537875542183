import React from 'react';
import Modal from '../Modal';
import { StyledContent, StyledText, StyledTitle } from './styles';

export default function EURecruitmentPolicy({
  closeModal,
  setModal,
  scrollTo,
  setLocation,
  hideClose,
}) {
  return (
    <Modal
      hideClose={hideClose}
      closeModal={closeModal}
      setModal={setModal}
      scrollTo={scrollTo}
      setLocation={setLocation}
      openCookieSettings={() => setModal('cookieSettings')}
      openPrivacyPolicy={() => setModal('privacyPolicy')}
      openRecruitmentPolicy={() => setModal('recruitmentPolicy')}
    >
      <StyledContent>
        <StyledTitle>EU recruitment policy</StyledTitle>
        <StyledText>
          <p>
            As part of our recruitment process, we collect and process personal
            data relating to job applicants. We are committed to transparency
            about how we collect and use that data, including ensuring we comply
            with legal obligations relating to data protection.
          </p>
          <h2>What information do we collect?</h2>
          <p>
            Through the recruitment process we collect a range of personal
            information about candidates. This can include:
          </p>
          <ul>
            <li>
              your name, address and contact details, including email address
              and telephone number;
            </li>
            <li>
              details of your qualifications, skills, experience and employment
              history;
            </li>
            <li>
              information about your current compensation, including benefit
              entitlements; and
            </li>
            <li> information about your entitlement to work in the UK.</li>
          </ul>
          <p>
            This information may be collated directly (where we act as the data
            controller), or through a recruitment agency (where we are
            considered the data processor). The personal information may be
            contained in CVs, obtained from your passport or other identity
            documents, or collected through interviews or tests as part of the
            recruitment process.
          </p>
          <p>
            If we collect personal data about you from third parties, such as
            references supplied by former employers, we will seek this
            information only once a job offer to you has been made unless stated
            explicitly otherwise.
          </p>
          <h2>Where is information held?</h2>
          <p>
            Data will be stored in a range of different places, including
            recruitment tracking spreadsheets, on our file server and on other
            IT systems (including email).
          </p>
          <h2>Why do we hold this information?</h2>
          <p>
            We have a legitimate business interest in processing data, in order
            to assess your suitability for future employment with the Company
            and decide whom to offer a role. We may also need to process data to
            ensure that we are complying with our legal obligations. For
            example, checking a successful applicant’s eligibility to work in
            the UK before employment starts.
          </p>
          <p>
            If your application is unsuccessful, we may keep your personal data
            on file in case there are future employment opportunities for which
            you may be suited. We will ask for your consent before we keep your
            data for this purpose and you are free to withdraw your consent at
            any time.
          </p>
          <h2>Who has access to data?</h2>
          <p>
            Your information may be shared internally only for the purposes of
            the recruitment exercise. This includes members of our operations
            team (HR and Finance), management and hiring managers involved in
            the recruitment process. IT staff will also have access to this
            data, as it is necessary for the performance of their roles. We will
            not share your data with third parties, unless your application for
            employment is successful and we make you an offer of employment. We
            will then share your data with former employers to obtain references
            for you, employment background check providers to obtain necessary
            background checks. Your information may also be shared internally
            within our global network to Head Office, located in Montreal, as
            part of our internal processing to ensure accurate record keeping.
          </p>
          <h2>How do we protect data? </h2>
          <p>
            We take the security of your data seriously. We have internal
            policies and controls in place to ensure that your data is not lost,
            accidentally destroyed, misused or disclosed, and is not accessed
            except by our employees in the proper performance of their duties.
          </p>
          <h2>How long do we keep your data? </h2>
          <p>
            If your application for employment is unsuccessful, the organisation
            will hold your data on file for 12 months after the end of the
            relevant recruitment process. If you agree to allow us to keep your
            personal data on file, we will hold your data on file for a further
            12 months for consideration for future employment opportunities. At
            the end of that period, or once you withdraw your consent, your data
            is deleted or destroyed. You will be asked when you submit your CV
            whether you give us consent to hold your details for the full 12
            months in order to be considered for other positions or not. If your
            application for employment is successful, personal data gathered
            during the recruitment process will be transferred to your Human
            Resources file (electronic and paper-based) and retained during your
            employment.
          </p>
          <h2>Your rights as a data subject</h2>
          <p>
            As a data subject, you have a number of rights. At any point while
            we are in possession of or processing your personal data, you, the
            data subject, have the following rights:{' '}
          </p>
          <p>
            Right of access &ndash; you have the right to request a copy of the
            information that we hold about you.
          </p>
          <p>
            Right of rectification &ndash; you have a right to correct data that
            we hold about you that is inaccurate or incomplete.
          </p>
          <p>
            Right to be forgotten &ndash; in certain circumstances you can ask
            for the data we hold about you to be erased from our records.
          </p>
          <p>
            Right to restriction of processing &ndash; where certain conditions
            apply to have a right to restrict the processing.
          </p>
          <p>
            Right of portability &ndash; you have the right to have the data we
            hold about you transferred to another organisation.
          </p>
          <p>
            Right to object &ndash; you have the right to object to certain
            types of processing such as direct marketing.
          </p>
          <p>
            Right to object to automated processing, including profiling &ndash;
            you also have the right to be subject to the legal effects of
            automated processing or profiling.
          </p>
          <p>
            Right to judicial review: in the event that Cherry refuses your
            request under rights of access, we will provide you with a reason as
            to why. You have the right to complain
          </p>
          <p>
            If you would like to exercise any of these rights, please contact
            recruitment@cherrythinking.com If you believe that the organisation
            has not complied with your data protection rights, you can complain
            to the Information Commissioner Office.
          </p>
          <h2>What if you do not provide personal data?</h2>
          <p>
            You are under no statutory or contractual obligation to provide data
            to us during the recruitment process. However, if you do not provide
            the information, we may not be able to process your application
            properly or at all.
          </p>
        </StyledText>
      </StyledContent>
    </Modal>
  );
}
