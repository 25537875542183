import styled from 'styled-components';

export const StyledContent = styled.div`
  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    padding: 0px 10% 0px 10%;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    padding: 0px 15% 0px 15%;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthDesktop}px) {
    padding-bottom: 0px;
  }

  ol {
    padding-left: 0;
    margin: 0 0 0 1em;
  }

  a {
    color: inherit;
    display: inline-block;
  }

  h2 {
    margin: 15px 0;
  }
`;

export const StyledTitle = styled.h1`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 30px;

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    font-size: 48px;
  }

  /* @media screen and (min-width: ${(props) =>
    props.theme.minWidthDesktop}px) {
    font-size: calc(100vw * 0.032);
  } */
`;

export const StyledText = styled.div`
  line-height: 1.3;
  font-size: 14px;
  white-space: pre-wrap;

  p {
    margin-block: 16px;
  }
  p + h2 {
    margin-top: 32px;
  }

  li {
    margin-top: 16px;
  }

  /* h2 {
    margin-block: 32px;
  } */

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    font-size: 16px;
  }

  /* @media screen and (min-width: ${(props) =>
    props.theme.minWidthDesktop}px) {
    font-size: calc(100vw * 0.011);
  } */
`;
