import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import VideoPlayer from '../VideoPlayer';

export default function ShowReel() {
  const { contentfulHomePage } = useStaticQuery(
    graphql`
      query {
        contentfulHomePage {
          videoUrl
        }
      }
    `,
  );

  return contentfulHomePage ? (
    <VideoPlayer url={contentfulHomePage.videoUrl} />
  ) : null;
}
