import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';

import { StaticQuery, graphql, useStaticQuery } from 'gatsby';
import WindowWidthContext, {
  useWindowWidth,
} from '../../context/WindowWidthContext';
import { fileSuffixFromWidth } from '../../helpers';

import {
  StyledVideoContainer,
  StyledPlayButton,
  StyledOverlay,
  StyledBackground,
} from './styles';

export default function VideoPlayer(props) {
  const data = useStaticQuery(graphql`
    query {
      cover_mobile: file(relativePath: { eq: "video/video_mobile.jpg" }) {
        childImageSharp {
          fixed(width: 320, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cover_tablet: file(relativePath: { eq: "video/video_tablet.jpg" }) {
        childImageSharp {
          fixed(width: 768, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cover_laptop: file(relativePath: { eq: "video/video_laptop.jpg" }) {
        childImageSharp {
          fixed(width: 1440, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cover_desktop: file(relativePath: { eq: "video/video_desktop.jpg" }) {
        childImageSharp {
          fixed(width: 2558, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const [showCover, toggleCover] = useState(props.autoPlay ? false : true);
  const [pauseVideo, togglePause] = useState(props.autoPlay ? false : true);
  const { width } = useWindowWidth();
  const [suffix, setSuffix] = useState(null);

  // const [ref, inView] = useInView({
  //   threshold: 0.85,
  // });

  // useEffect(() => {
  //   if (inView === true) {
  //     props.setActiveView(inView, 'video');
  //     window.location.hash = 'our-work';
  //   } else if (inView === false && showCover === false) {
  //     togglePause(true);
  //   }
  // }, [inView]);

  useEffect(() => {
    setSuffix(fileSuffixFromWidth(width));
  }, [width]);

  return (
    <StyledVideoContainer>
      <ReactPlayer
        url={props.url}
        width={'100%'}
        height={'100%'}
        playing={!pauseVideo}
        playsinline={true}
        controls={true}
        onPlay={() => togglePause(false)}
        style={{
          visibility: showCover === true ? 'hidden' : 'visible',
        }}
      />
      <StyledOverlay style={{ display: showCover === true ? 'flex' : 'none' }}>
        {suffix ? (
          <StyledBackground
            bgurl={data['cover' + suffix].childImageSharp.fixed.src}
          />
        ) : null}
        <StyledPlayButton>
          <img
            src="video-arrow.svg"
            onClick={() => {
              toggleCover(false);
              togglePause(false);
            }}
          />
        </StyledPlayButton>
      </StyledOverlay>
    </StyledVideoContainer>
  );
}

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  width: PropTypes.string,
};

VideoPlayer.defaultProps = {
  width: '100%',
};
