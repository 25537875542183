import styled from 'styled-components';
import { animated } from 'react-spring';

export const StyledLocationPhone = styled.div`
  background: ${(props) => props.theme.staticLinearGradient};
  width: 100%;
  grid-row: 2 / span 2;
  z-index: 2;
  color: white;
  font-weight: 300;
  padding: 15px 12px;

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    padding-left: 11%;
  }
`;

export const StyledLocationTitle = styled.h2`
  font-weight: 100;
  font-size: 32px;

  margin-bottom: 0;

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    font-size: 36px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthDesktop}px) {
    font-size: 60px;
  }

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-left: -21vw !important;

    margin-top: 15px !important;
  } */
`;

export const StyledLocationInfo = styled.p`
  font-size: 14px;

  line-height: 1.43;

  margin-top: 10px;

  a {
    text-decoration: none;
    color: inherit;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    font-size: 16px;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthDesktop}px) {
    font-size: 26px;
  }

  /* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-left: -21vw !important;
  } */
`;

export const StyledOtherOfficeContainer = styled.div`
  margin-top: 2em;

  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.locationView === 'london' ? 'row' : 'row-reverse'};
  justify-content: ${(props) =>
    props.locationView === 'london' ? 'flex-start' : 'flex-end'};
`;

export const StyledOtherOfficeLon = styled.p`
  font-size: 14px;
  font-weight: bold;

  text-transform: uppercase;

`;

export const StyledOtherOfficeNY = styled.p`
  font-size: 14px;
  font-weight: bold;

  text-transform: uppercase;

`;

export const StyledOtherOfficeChicago = styled.p`
  font-size: 14px;
  font-weight: bold;

  text-transform: uppercase;

`;

export const StyledNextArrow = styled.p`
  /* position: relative; */
  /* bottom: 21%;
  left: 32%; */
  display:inline-block;
  margin-left:10px
`;

export const StyledBackArrow = styled.p`
  /* position: relative; */
  /* bottom: 21%;
  left: 0%; */
`;

// --- Tablet and above styled components ---

export const StyledLocation = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  font-weight: 300;
`;

export const StyledLocationSplitInfo = styled(animated.div)`
  /* grid-column: ${(props) => props.gridcol};
  width: 100%;
  
  z-index: ${(props) => props.zindex};
  align-self: end;
  /* position: absolute; */
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0; */
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  padding: 15px 12px;
  transition: height 0.3s, transform 0.3s;
  padding-left: 11%;

  /* @supports (-ms-ime-align: auto) {
    width: 50vw;
  } */

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 12px;
    padding-left: 15%;

    h2 {
      margin: 0;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    align-items: initial;
    width:100%
  }
`;

export const LocationPanel = styled.div`
  width: 100%;
  position: relative;
  display:flex
`;
