import React from 'react';
import marked from 'marked';

import Modal from '../Modal';
import { StyledContent, StyledTitle, StyledText } from './styles';
import { useStaticQuery, graphql } from 'gatsby';

const PrivacyPolicy = ({
  closeModal,
  setModal,
  scrollTo,
  setLocation,
  hideClose,
}) => {
  const { data } = useStaticQuery(graphql`
    query {
      data: contentfulTermsAndConditions {
        content {
          content
        }
        title
      }
    }
  `);

  const { content, title } = data;

  return (
    <Modal
      hideClose={hideClose}
      closeModal={closeModal}
      setModal={setModal}
      scrollTo={scrollTo}
      setLocation={setLocation}
      openCookieSettings={() => {
        setModal('cookieSettings');
      }}
      openPrivacyPolicy={() => {
        setModal('privacyPolicy');
      }}
    >
      <StyledContent>
        <StyledTitle>{title || '-'}</StyledTitle>
        <StyledText
          dangerouslySetInnerHTML={{
            __html: content.content ? marked(content.content) : '',
          }}
        ></StyledText>
      </StyledContent>
    </Modal>
  );
};

export default PrivacyPolicy;
