import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 33px;
  font-size: 13px;
  user-select: none;
`;

export const Label = styled.label`
  margin-bottom: 0;
`;

export const SwitchButton = styled.div`
  background: #fff;
  width: 65px;
  height: 33px;
  border: 2px solid #fff;
  border-radius: 25px;
  padding: 2px 2px 2px 2px;
  transition: border-color 0.3s;
  font-size: 10px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  opacity: ${props => (props.isEssential ? 0.7 : 1)};

  color: ${props =>
    props.isChecked ? (!props.isEssential ? '#91004b' : '#3d3f46') : '#fff'};
  background: ${props => (props.isChecked ? '#fff' : 'inherit')};

  &::after {
    content: '';
    width: 25px;
    height: 25px;
    display: block;
    background-color: #fff;
    transition: transform 0.3s, background-color 0.3s;
    border-radius: 50%;

    ${({ isChecked, isEssential }) => {
      if (isChecked) {
        if (!isEssential) {
          return `transform: translateX(32px);
    background: #91004b;`;
        }

        return `transform: translateX(32px); background: #3d3f46;`;
      }
    }}
  }
`;

export const Input = styled.input`
  &:checked + ${SwitchButton} .switch-button__label--Off {
    opacity: 0;
  }
  &:checked + ${SwitchButton} .switch-button__label--On {
    opacity: 1;
  }
`;

export const Text = styled.span`
  position: absolute;
  transition: opacity 0.3s;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-weight: 800;
`;
