import React from 'react';
import Carousel from '../Carousel';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

export default function Services() {
  const { contentfulCarousel } = useStaticQuery(graphql`
    query {
      contentfulCarousel(entryTitle: { eq: "Middle Carousel" }) {
        heading
        copy {
          copy
        }
        carouselSlides {
          iconJson {
            file {
              url
            }
          }
          svgSlideHeading
          svgSlideCopy {
            svgSlideCopy
          }
        }
      }
    }
  `);

  const servicesCopy = `Our experience spans secondary and primary care, and OTC. Whether it be global, EMEA or domestic, our work is built on a solid understanding of our audiences.`;

  return (
    <Wrapper>
      <Carousel
        data={{ ...contentfulCarousel, copy: { copy: servicesCopy } }}
        viewName="services"
      />
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${props => props.theme.lightGrey};
`;
