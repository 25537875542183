import styled from 'styled-components';
import { StyledHamburgerWrapper } from '../HamburgerMenu/styles';

export const StyledPage = styled.div`
  /* width: 100vw;
  min-height: 100vh; */
  background: #3d3f46;
  color: #ffffff;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  ${StyledHamburgerWrapper} {
    z-index: 9999;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledContainer = styled.div`
  width: 100%;
  background: #3d3f46;
  z-index: 8;
  color: #ffffff;
  padding: 0px 20px;
  flex-grow: 1;
  /* flex: auto; */
  /* justify-content: center; */

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding: 0px 50px 0px 50px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding: 0px 100px 0px 60px;
  }
`;

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 40px 0px;

  svg {
    width: 80px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    padding: 30px 0px 80px 0px;

    svg {
      display: none;
    }
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
    padding-left: 150px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
    padding: 50px 800px 100px 150px;

    svg {
      width: 145px;
    }
  }
`;

export const StyledCross = styled.div`
  position: absolute;
  top: 3vh;
  right: 5vw;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;

  svg {
    width: 50%;
    fill: ${props => props.theme.primaryCherry};
  }

  @media screen and (min-width: ${props => props.theme.minWidthTablet}px) {
    top: 3vh;
    width: 48px;
    height: 48px;
  }

  @media screen and (min-width: ${props => props.theme.minWidthLaptop}px) {
  }

  @media screen and (min-width: ${props => props.theme.minWidthDesktop}px) {
  }
`;

export const CloseIcon = styled.button`
  height: 48px;
  width: 48px;
  border: none;
  border-radius: 50%;
  background: white;
  position: absolute;
  right: 3em;
  top: 3em;
  z-index: 10000;
`;
