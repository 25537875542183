import styled from 'styled-components';
import { Button } from '../Layout';

const StyledCookieBackground = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: flex-end;

  ::before {
    content: '';
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    /* height: 26%; */
    display: none;
  }
/* 
  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    height: 200px;
    width: 715px;

    bottom: 3%;
    right: 2%;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthDesktop}px) {
    height: 200px;
  } */
`;

const StyledCookieContainer = styled.div`
  box-shadow: 0 12px 20px 0 rgba(32, 32, 32, 0.2);
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 9999;
  /* height: 70%; */

  /* position: relative; */

  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* display: grid;
  grid-template-rows: 10% 22% 1% 5% 5% 38% 4% 20% 1fr;
  display: -ms-grid;
  -ms-grid-rows: 10% 20% 6% 5% 5% 35% 4% 20% 1fr; */

  padding: 25px;

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    /* height: 100%; */
    /* bottom: 0; */
    /* grid-template-rows: 22% 42.5% 1fr;
    grid-template-columns: 27% 1fr;

    -ms-grid-rows: 22% 42.5% 1fr;
    -ms-grid-columns: 27% 1fr; */
    /* width: 50vw; */
    border-radius: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    width: auto;
    display: flex;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    border-radius: 10px;
    left: auto;
    max-width: 715px;
  }
`;

const StyledImage = styled.div`
  /* -ms-grid-row: 2;
  -ms-grid-row-span: 1;

  grid-row: 2 / span 1; */
  max-width: 180px;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    margin-right: 15px;
    margin-bottom: 0;
  }

  /* @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    -ms-grid-row-align: center;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    align-items: flex-end;
    grid-column: 1 / span 1;
    align-self: center;
    height: 100%;
    width: 70%;
  } */
`;

const StyledTitle = styled.h4`
  font-size: 16px;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
  /* grid-row: 4 / span 1; */

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    /* -ms-grid-row-align: center;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1; */
    /* 
    grid-row: 1 / span 1;
    grid-column: 2 / span 1; */
    align-items: center;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    align-items: flex-end;
  }
`;

const StyledText = styled.p`
  /* -ms-grid-row: 6;
  -ms-grid-row-span: 1; */

  font-size: 14px;
  /* grid-row: 6 / span 1; */
  margin: 0;
  line-height: 1.43;
  margin-bottom: 15px;

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    /* -ms-grid-row-align: center;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;

    grid-row: 2 / span 1;
    grid-column: 2 / span 1; */
    align-self: center;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    /* margin-right: 2%; */
    line-height: 1.3;
    font-size: 14px;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* grid-area: 8 / 1 / span 1 / span 1; */
  justify-content: space-between;

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    /* -ms-grid-row-align: center;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;

    grid-row: 3 / span 1;
    grid-column: 2 / span 1; */
    align-self: center;
    justify-content: flex-start;

    ${Button}:first-child {
      margin-right: 10px;
    }
  }
`;

const StyledButton = styled.button`
  /* -ms-grid-row: 8;
  -ms-grid-row-span: 1;

  grid-row: 8 / span 1; */
  width: 41%;
  height: 37px;
  border-radius: 22px;
  border: none;
  background: ${(props) =>
    props.className === 'accept' ? props.theme.primaryCherry : '#ffffff'};
  color: ${(props) =>
    props.className === 'accept' ? '#ffffff' : props.theme.primaryCherry};
  border: 2px solid ${(props) => props.theme.primaryCherry};
  font-family: 'Lato';
  font-weight: 500;
  font-size: 14px;
  transition: background 300ms ease;

  :hover,
  :active {
    background: ${(props) =>
      props.className === 'accept' ? '#ffffff' : props.theme.primaryCherry};
    color: ${(props) =>
      props.className === 'accept' ? props.theme.primaryCherry : '#ffffff'};
    cursor: pointer;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthTablet}px) {
    /* -ms-grid-row-align: center;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;

    grid-row: 3 / span 1;
    grid-column: 2 / span 1; */
    align-self: center;
    width: 40%;
  }

  @media screen and (min-width: ${(props) => props.theme.minWidthLaptop}px) {
    /* width: 125px; */
    :first-child {
      margin-right: 20px;
    }
  }
`;

const StyledCookieLink = styled.a`
  color: ${(props) => props.theme.primaryCherry};
  text-decoration: underline;
  cursor: pointer;
`;

export {
  StyledCookieBackground,
  StyledCookieContainer,
  StyledImage,
  StyledTitle,
  StyledText,
  StyledButton,
  StyledCookieLink,
};
