import React from 'react';
import GoogleMapReact from 'google-map-react';
import { InView } from 'react-intersection-observer';

import CherryMarker from '../../images/markers/cherrymarker.svg';
import Direction from '../../images/directions.svg';
import WindowWidthContext from '../../context/WindowWidthContext';
import LondonBridgeMarker from '../../images/markers/london-bridge.svg';
import BermondseyMarker from '../../images/markers/bermondsey.svg';
import ShardMarker from '../../images/markers/shard.svg';
import GuysMarker from '../../images/markers/guys.svg';

import {
  StyledGoogleMapOptions,
  StyledPicker,
  StyledDirections,
  MapWrapper,
} from './styles';

// Todo  -- Make the marker animate out in tablet and below after a small delay
const Marker = props => (
  <WindowWidthContext.Consumer>
    {({ width }) => (
      <StyledPicker onMouseLeave={() => props.resetFunc()}>
        <CherryMarker
          style={{ position: 'relative', zIndex: 5 }}
          onMouseEnter={() => props.hoverFunc()}
        />
        <StyledDirections>
          <a href={props.direction} target="_blank" rel="noopener noreferrer">
            {/* Check if screen size is under desktop, if it is display the label out and don't animate it */}
            <Direction
              style={{ width: width < 1020 ? '178px' : props.theWidth + 'px' }}
            />
          </a>
        </StyledDirections>
      </StyledPicker>
    )}
  </WindowWidthContext.Consumer>
);

const MarkerContainer = props => (
  <div>
    <props.image />
  </div>
);

class GoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zoomLevel: 14.5,
      startNumber: 0,
    };
  }

  animateZoom = () => {
    setTimeout(() => {
      let zoomAnim = setInterval(() => {
        this.setState({ zoomLevel: (this.state.zoomLevel += 0.1) });

        if (this.state.zoomLevel >= 15.1) {
          clearInterval(zoomAnim);
        }
      }, 80);
    }, 100);
  };

  animateDirections = () => {
    let theInterval = setInterval(() => {
      this.setState({ startNumber: (this.state.startNumber += 7) });

      if (this.state.startNumber > 178) {
        clearInterval(theInterval);
      }
    }, 5);
  };

  resetState = () => {
    this.setState({ startNumber: 0 });
  };

  // calcMapHeight = width => {
  //   return width < 450
  //     ? "49%"
  //     : width >= 450 && width < 1050
  //     ? "71%"
  //     : width >= 1050 && width < 1700
  //     ? "66%"
  //     : width >= 1700
  //     ? "66%"
  //     : null;
  // };

  render() {
    return (
      <InView
        triggerOnce={true}
        onChange={inView => {
          if (inView === true) {
            this.animateZoom();
          }
        }}
      >
        {({ ref }) => (
          <MapWrapper
            style={{
              width: '100%',
              flexGrow: 1,
            }}
            className={this.props.className}
            ref={ref}
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyDFbrbdD3WrCEwLFcMBXxyjb9iSDeE8zyo',
              }}
              defaultCenter={{ lat: 51.50788614475553, lng: -0.09491010215249314 }}
              center={this.props.defaultCenter}
              zoom={this.state.zoomLevel}
              options={StyledGoogleMapOptions}
            >
              <Marker
                lat={51.50788614475553}
                lng={-0.09491010215249314}
                theWidth={this.state.startNumber}
                hoverFunc={this.animateDirections}
                resetFunc={this.resetState}
                direction="https://www.google.com/maps/place/Riverside+House,+2A+Southwark+Bridge+Rd,+London+SE1+9HA/@51.5077259,-0.0970988,17z/data=!3m1!4b1!4m5!3m4!1s0x487604a9c70c9f43:0xb5ca59f2f86b9f8c!8m2!3d51.5077259!4d-0.0949101"
              />
              <Marker
                lat={40.710163}
                lng={-74.012525}
                theWidth={this.state.startNumber}
                hoverFunc={this.animateDirections}
                resetFunc={this.resetState}
                direction="https://www.google.com/maps/dir//Four+World+Trade+Center,+150+Greenwich+St,+New+York,+NY+10007,+USA/@40.7102889,-74.0144788,17z/data=!4m17!1m7!3m6!1s0x89c25a19fa24b37b:0xe82cd7d29692669c!2sFour+World+Trade+Center,+150+Greenwich+St,+New+York,+NY+10007,+USA!3b1!8m2!3d40.7102889!4d-74.0122901!4m8!1m0!1m5!1m1!1s0x89c25a19fa24b37b:0xe82cd7d29692669c!2m2!1d-74.0122901!2d40.7102889!3e2"
              />

            <Marker
                lat={41.877181}
                lng={-87.633865}
                theWidth={this.state.startNumber}
                hoverFunc={this.animateDirections}
                resetFunc={this.resetState}
                direction="https://www.google.com/maps/dir//404+S+Wells+St+2nd+Floor,+Chicago,+IL+60607,+USA/@41.8766385,-87.6364819,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x880e2cbdf87a5dc1:0xf761e455ccf6bf42!2m2!1d-87.633907!2d41.8766345?entry=ttu&g_ep=EgoyMDI0MDkyMy4wIKXMDSoASAFQAw%3D%3D"
              />

              <MarkerContainer
                image={LondonBridgeMarker}
                lat={51.504573}
                lng={-0.086054}
              />
              <MarkerContainer
                image={BermondseyMarker}
                lat={51.4979}
                lng={-0.0637}
              />
              <MarkerContainer
                image={ShardMarker}
                lat={51.502664656}
                lng={-0.085332992}
              />
              <MarkerContainer
                image={GuysMarker}
                lat={51.5034583333}
                lng={-0.0868694444444}
              />
            </GoogleMapReact>
          </MapWrapper>
        )}
      </InView>
    );
  }
}

export default GoogleMap;
