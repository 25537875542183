import React, { useState } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Introduction from '../components/Introduction';
import ShowReel from '../components/ShowReel';
import Services from '../components/Services';
import MapContainer from '../components/MapContainer';
import JobSlider from '../components/JobSlider';
import Footer from '../components/Footer';
import CookieBanner from '../components/CookieBanner';
import CookieSettings from '../components/CookieSettings';
import { setCookie, searchCookie } from '../helpers/cookieUtils';
import Scroller, { Section } from '../components/Scroller';
// import AnimatedSplashScreen from '../components/AnimatedSplashScreen';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Navigation from '../components/Navigation';
import EURecruitmentPolicy from '../components/EURecruitmentPolicy';

const sections = [
  { component: Hero, id: '' },
  { component: Introduction, id: 'about' },
  { component: ShowReel, id: 'our-work' },
  { component: Services, id: 'services' },
  { component: JobSlider, id: 'careers' },
  { component: MapContainer, id: 'location' },
];

export default function IndexPage() {
  const cookiesEnabled =
    typeof document !== 'undefined' &&
    document.cookie.includes('CookiePolicy=true')
      ? true
      : false;
  const [cookieConsent, setCookieConsent] = useState(cookiesEnabled);
  const [cookiesRead, setCookiesRead] = useState(cookiesEnabled);
  const [modal, setModal] = useState('');
  const [showContactPage, setShowContactPage] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const removeCookies = () => {
    const domain = window.location.hostname;
    const analyticsCookie = searchCookie('UA-')[0];

    [domain, `.${domain}`].forEach(() => {
      setCookie('_ga', '', '0', domain);
      setCookie('_gid', '', '0', domain);
      setCookie('_gat', '', '0', domain);

      if (analyticsCookie) {
        setCookie(analyticsCookie, '', '', domain);
      }
    });

    window.ga = function () {
      return false;
    };
  };

  const setCookies = (isOn) => {
    const domain = window.location.hostname;
    setCookie('CookiePolicy', isOn, '', domain);
    setCookieConsent(isOn);

    if (!isOn) {
      removeCookies();
    }
  };

  const closeModal = () => {
    history.pushState(null, null, `#`);
    setModal('');
  };

  return (
    <Layout>
      <SEO
        GAEnabled={cookieConsent}
        title="Award-winning global healthcare creative agency"
        keywords={[`cherry`, `digital`, `advertising`, `healthcare`]}
      />
      <Scroller>
        <Navigation />
        {sections.map(({ component, id }, index) => {
          return (
            <Section key={`${id}-${index}`} Component={component} id={id} />
          );
        })}
        <Footer
          openCookieSettings={() => setModal('cookieSettings')}
          openPrivacyPolicy={() => setModal('privacyPolicy')}
          openRecruitmentPolicy={() => setModal('recruitmentPolicy')}
          closeModal={closeModal}
        />
        <Modals
          currentModal={modal}
          closeModal={closeModal}
          setCookies={setCookies}
          cookieConsent={cookieConsent}
          setCookiesRead={setCookiesRead}
          setModal={setModal}
        />
      </Scroller>
      {!cookiesRead && (
        <CookieBanner
          setCookieConsent={setCookies}
          setCookiesRead={setCookiesRead}
          setModal={setModal}
        />
      )}
      {/* <AnimatedSplashScreen /> */}
    </Layout>
  );
}

function Modals({
  currentModal,
  closeModal,
  setCookies,
  cookieConsent,
  setCookiesRead,
  setModal,
}) {
  return (
    <>
      {currentModal === 'cookieSettings' && (
        <CookieSettings
          closeModal={closeModal}
          setCookies={setCookies}
          cookieConsent={cookieConsent}
          setModal={setModal}
          openCookieSettings={() => setModal('cookieSettings')}
          openPrivacyPolicy={() => setModal('privacyPolicy')}
          openRecruitmentPolicy={() => setModal('recruitmentPolicy')}
        />
      )}
      {currentModal === 'privacyPolicy' && (
        <PrivacyPolicy
          setCookieConsent={setCookies}
          setCookiesRead={setCookiesRead}
          setModal={setModal}
          closeModal={closeModal}
          openCookieSettings={() => setModal('cookieSettings')}
          openPrivacyPolicy={() => setModal('privacyPolicy')}
          openRecruitmentPolicy={() => setModal('recruitmentPolicy')}
        />
      )}
      {currentModal === 'recruitmentPolicy' && (
        <EURecruitmentPolicy
          setCookieConsent={setCookies}
          setModal={setModal}
          closeModal={closeModal}
          openCookieSettings={() => setModal('cookieSettings')}
          openPrivacyPolicy={() => setModal('privacyPolicy')}
          openRecruitmentPolicy={() => setModal('recruitmentPolicy')}
        />
      )}
    </>
  );
}
