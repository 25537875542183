import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import CookieLottieData from '../../images/lottie/cookie1.json';

import {
  StyledCookieBackground,
  StyledCookieContainer,
  StyledImage,
  StyledTitle,
  StyledText,
  StyledButton,
  StyledCookieLink,
  StyledButtonContainer,
} from './styles';
import { setCookie } from '../../helpers/cookieUtils.js';
import { Button } from '../../components/Layout';

const CookieBanner = ({ setModal, setCookieConsent, setCookiesRead }) => {
  function setCookies() {
    setCookie('CookiePolicy', 'true');
    setCookieConsent(true);
    setCookiesRead(true);
  }

  const lottieCookieOptions = {
    animationData: CookieLottieData,
    autoplay: true,
    direction: 1,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
    },
  };

  return (
    <>
      <StyledCookieBackground></StyledCookieBackground>
      <StyledCookieContainer>
        <StyledImage>
          <Lottie
            isClickToPauseDisabled={true}
            options={lottieCookieOptions}
            height={'auto'}
            width={'100%'}
          />
        </StyledImage>
        <div>
          <StyledTitle>We use cookies</StyledTitle>

          <StyledText>
            Cherry uses cookies to improve this website. You may disable the use
            of cookies; however, some are essential for the website to work
            properly. To amend your preferences, visit “Cookie settings” below
            or "Accept cookies" to confirm that you have read and accepted the
            terms of our {''}
            <StyledCookieLink
              onClick={() => {
                setModal('cookieSettings');
                setCookiesRead(true);
              }}
            >
              cookie settings.
            </StyledCookieLink>
          </StyledText>

          <StyledButtonContainer>
            <Button
              className="accept"
              onClick={() => {
                setCookies(true);
              }}
            >
              Accept cookies
            </Button>
            <Button
              secondary
              onClick={() => {
                setModal('cookieSettings');
                setCookiesRead(true);
              }}
            >
              Cookie settings
            </Button>
          </StyledButtonContainer>
        </div>
      </StyledCookieContainer>
    </>
  );
};

CookieBanner.propTypes = {
  setModal: PropTypes.func,
};

export default CookieBanner;
